import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.0.1</Title>
    <Title order={3} fs="bold">Year 154</Title>
    <Text>{`This time, the figments weren’t fighting each other. Rather, they were making some level of peace.

One was made purely of light, some type of spectral formation. The other was an amorphous shadow, no distinguishable features. It was almost like they were conversing.

Her imagination would often come to realization in front of her. She imagined the fire dancing, so it would. If the darkness sprung to life in her mind, it would fly off the ground, as if she willed it with her mind.

Mostly because she did.

It had been over a century and a half since everybody died. Since the rift was opened, since the behemoth consumed everybody else. It’s been a lonely number of years, but those feelings have long since dissolved for the most part.

They called her Perfection when they were alive, and the irony set in pretty quickly. She felt it vain to call herself that, but ultimately words have lost their meaning once there was no reason to say them anymore. There was nobody to say them to.

She was nearing the end of her journey. According to her research, she has touched every piece of land on the planet, or at least within a decent vicinity of each one. It was true; the lands have been left soulless, literally. Perfection was now finally back at her original home, what they once called the Horn Range.

She watched the forest around her. Some creatures had survived, the ones with no agency. Small insects, microscopic organisms, some very basic plants as well. Most of the fungi died off long ago. As per usual, it was eerily silent. The only sounds that ever surfaced were the ones she decided would.

Perfection sat down exactly where she decided she would when she started this journey: a small green fire that wouldn’t seem to go out. In fact, it’s one of the only phenomena that wouldn’t obey her mental whims. It was relieving in a way, finally having something that didn’t appear at her beckoning call. No matter what she did, thought, said, this magical green fire kept dancing to its own rhythm.

A creature once appeared from this fire, not long ago. A humanoid with the wings of a pixie and bright green eyes. He didn’t listen to her either; in fact, he actually retorted her a lot of the time. Making weird, witty comments about what had happened. She presumed that he was a conjuration of her mind, perhaps a part of her that remained in conflict with decisions.

It was only a handful of miles away that it all went down. The crag in the ground left a scar on the earth. Oddly enough, the fire seemed to retort, replaying the memory back to her without her permission.

—

It was born around here, somewhere in the Horn Range. She never got much of a chance to even grasp a name for it, most just called it the behemoth.

It consumed everything in sight. Its figure is hard to explain, almost incomprehensible in nature. A mechanical body, something more immense than she had ever witnessed, but it was cloaked in what felt like something that wasn’t meant to be seen. Some type of magical essence perhaps, or maybe just some kind of illusion.

It killed everything it touched. Not in the way that a creature its size would. No, it simply consumed the essence of everything around it. A soul eater of types. As it walked through the forest, all the trees would lose their color. The animals would simply fall out of their trees, nothing left in their minds. Even humanoids that came across it had no expression, not even fear. They simply ceased to think, to feel anything at all.

Perfection was raised being told that something terrible would happen to this world. They named her Perfection for that very reason: she would be the hero. She would slay this monumental entity, halt it in its tracks.

Unfortunately, they were right, in a way.

Nobody stood even a moniker of a chance against this thing. Whatever it wished, it would succeed. Her instinct told her that it was artificial, that somebody fabricated this thing. To what end, she had no idea, but she couldn’t find any other way around it.

She watched the life of entire cities extinguish from miles away. It wasn’t something visual, never something you could hear or touch. No, you could feel it. As if it consumed the color from life as it walked by, leaving a grayscale trail as it pushed forward.

She spent a lot of her youth learning about leylines; the natural flow of mana. Mana was chaotic by nature, countered by balance in some ways. Huge lines traveled like rivers throughout the world, following the traces of harmonic turbulence. They often conglomerated around things like fault lines, volcanos, areas of great natural power. She spent years following these leylines, studying them, watching their organic motions as they moved.

However, today made it feel like it was all worthless.

Every single leyline fled in the same direction: straight into the behemoth. As if soaking up the soul of every agent creature didn’t cure its hunger, it was consuming all of the mana in the world as well. It devoured all of the color in the world, leaving this black and white sultry in its wake.

One single person concocted an idea to stop the thing. They seemed only to be passing through when the end times took place, though they sat close enough for Perfection to listen.

The Aether; a dimension made purely of information. No matter, no energy, just information. More importantly, the parallel world where our souls reside. Every decision-making creature owns a portion of the vast infinity, holding onto their little bit of real estate for as long as it remains capable.

“I think it will destroy itself if it consumes too much,” they said. They had dark hair and deep gray eyes she’d never forget. Their expression was cold and calculated, not for a moment did they waiver. Even as they stared directly into the fate of the world, you’d think that nothing was happening at all.

“If we lead it to the Aether, it’ll overload. Once it reaches infinity, it will fail before it could ever finish.”

And that’s what they did.

The ground shook while the rift tore the earth apart. A door to a place that doesn’t really exist; at least, not to any extent that we do. And like a dog, the behemoth ran straight for it. An infinite meal for a creature of ceaseless hunger. It was the perfect bait.

It was consumed for hours. She watched it all happen, watched everybody around her die as their souls were ripped from their bodies, but now from the other side. The one who created the rift realized only moments after it was too late. Now it wasn’t the planet that was doomed, it was the entire universe. The creature had to fail at some point, but it felt like it never would. More and more, it kept going. Everybody in her village fell as if they were made of stone, even the person who derived the plan and tore the earth open. Lifeless, laying in stasis on the ground, facing the creature that would end it all.

She swears unto this day, she made eye contact with that formless entity, regardless of its lack of eyes. Something snapped inside her, and she became a beacon, some type of conduit in that moment. In the blink of an eye, when the creature finally failed to contain it all and the essence needed somewhere to go, it escaped into her.

—

“Enjoying the visuals?” The creature with green eyes was in front of her, twirling his fingers around to the beat of the fire. She blinked a couple of times, a bit confused by his presence.

Leylines were something of a lost art to her nowadays. Trying to study an influx of mana streaming directly into her was overwhelming at best. However, this creature seemed to disturb that flow to some extent. So did the human sitting next to him.

She did a double take.

A human? Even worse, somebody so familiar to her. Dark hair, deep gray eyes. That was–

“Whatcha think of the place?” The green-eyed creature asked the human. He was levitating in a reclined position, as if sitting in a chair that had been folded back. However, they didn’t respond. No, their expression was cold, nearly expressionless. They were simply studying their surroundings. The leylines, Perfection herself even.

Her mind immediately filled with questions and an overwhelm of emotions she hadn’t felt for over a hundred years, but they broke the silence before she could, “I know.”

Their voice was rigid and unwavering, “A godhood you never asked for. Perhaps yours was much more sudden, though. I can’t imagine it quite to your extent, in all honesty.”

She stopped for only a moment. The silence filled her lungs, “Yeah.”

It dumbfounded her how long it had been since she heard her own voice, much less the voice of another sentient creature. She wondered where they came from, what they were doing here, and how they even got here in the first place. Were they the same one she knew before, or another version of them? They continued on, “Out of every timeline, this Alyx was the boldest.” They stepped away from the fire, watching the trees and the overgrowth that covered everything. “I wish they had more time to consider their options, this is quite the punishment for somebody innocent.”

Long before any questions could be answered, they were gone. Much to her dismay, the green-eyed creature seemed rather dismissive, almost disappointed, though not at all surprised. He sighed dramatically, “They’re always so foreboding.” `}</Text>
  </div>
)

export const metadata = {
  title: 'Perfection',
  author: 'Orident',
  date: 'Sept 2, 2024',
  accent: '#00ff00',
  foreground: 'black',
  id: 'perfection',
  snippet: "This time, the figments weren’t fighting each other. Rather, they were making some level of peace. One was made purely of light, some type of spectral formation. The other was an amorphous shadow, no distinguishable features. It was almost like they were conversing."
}

export default Story;