import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.2.0</Title>
    <Title order={3} fs="bold">Rev 20-12</Title>
    <Text>{`Wendi was happy that she had no sense of smell anymore. What a miserable existence it would be, feasting on carcasses and fresh kills with the odor of rotten decay filling her nostrils. Or at least, what's left of them, perhaps.

After finishing her meal, her senses finally began to recollect for a moment. She looked down at the depravity she caused, somebody she's never met before. The guilt filled her stomach more than the meat did, but the sheer amount of times that it has happened helped scrub her with desensitization.

She hates identifying the bodies, but they stood out to her for some reason. The dark hair, the inquisitive look in their eyes. Even in death.

Her long body scampered off into the grotto, it should be a decent while before the hunger strikes again. It may be a good idea to try to appease it before it returns, though the chances have been slim recently. 

Thoxica, her new home. Veins of black ichor ran like rivers all around. Mutilated creatures dragged their limbs across the ground, aberrations bathed in filth and pain running rampant through these parts.

Monsters, just like her.

Even cloaked in darkness, her eyes pierced the night and her legs carried her swiftly. It was odd, never feeling pain, never feeling fatigued, not even an itch beneath her skin. No, as if her energy was infinite.

Before she touched the ichor, her joints flared regularly. Mild pains in her lower spine and behind her knees, just little reminders of her humanity perhaps. What a thing to take for granted.

The sounds of the forest were different now. The light coos and howls turned to gargled messes and the occasional screech of pain. Her new hands and feet were dexterous, barely making sound when she approached; the brush, the thin twigs and branches beneath her, the dusty dry leaves, all left in pristine condition as she passed by.

She was racing forward, flying through the thicket. She had a specific place in mind; there's a small camp somewhere a bit south of where she was currently.

It didn't take long at her speed. There it was, right ahead. The flare of light, a mild orange glare between the decrepit trees. Small billows of smoke danced above the embers, and just across it all, a small boy. A child with bright yellow eyes.

With haste she leapt out into the clearing, her thick black fur barely even grazing the obstacles she expertly navigated. The astonishing reflexes were a nice surprise for her, the agility, perfected vision, the power in her scrawny limbs.

Within just moments she landed down next to the dying fire, inches from the pale face of the young boy. His sad expression didn't do much to budge at all, barely did his eyes even follow her. His features were drowned in depressive acceptance.

“Hi, Wendi,” he muttered excitedly, offering a small, fragile hand out to her. His face perked up a little. Gently, she rubbed the boney shell that comprised her face against his palm.

He looked through the orbitals of her face, directly into her eyes. It was unlike anything before, the person he saw. Not this monster she had become, no. Who she was before the ichor, before she cannibalized to survive. Before she made meals from the flesh of rotten wildlife.

A talkative middle aged woman, born and raised not too far from where the crater emerged. The crater that tainted Thoxica, and then the rest of the world.

The boy caressed the long bridge of her face, above the massive row of teeth she had covered in blood. An untrained eye would call this boy naive, but it was almost like he was a bit too observant. Like he understood too much, and just wanted it to be a little simpler.

His gaze galloped into the sky, “There's a lot going up there.” His small finger pointed up to the full moon.

Walteria, they decided. The moon colonizers, that is. Those safe up in their subspace bubble, sheltered from the hell that crawled its way onto earth.

Sometimes she liked to watch the huge light they shone back down onto the earthlings. Some type of code they use to communicate with, perhaps. She was never sure who they were talking to, but perhaps somebody was listening.

“They're sending ships down,” he whispered. “They're looking for a cure, but they need a lot of people. Preferably healthy people.”

Though the bone plates that comprised her face weren't capable of expression, she looked at him dumbfounded. Without a word, a month of realization washed over her, now noticing it all at once. For the past month, his endless gaze into night was more than just wonder. No, he was listening.

She took a handful of the embers from the fire, her tough skin unfazed by the heat. Tossing them around as if they were illuminated pigments, she scribed a question mark into the ground in front of him using the point of her nail.

He looked back up at the moon, the bright signal lighting up once more, “It's called apparatus code.”

Once her thoughts finally aligned once more and the surprise dissipated, a well of anxiety submerged her. Though perhaps it was a small stretch in getting to the conclusion, she couldn't help but wallow in her fears.

Was he thinking about going?

—

The walk was slow, almost peaceful.

Day began to break when they decided to begin their trek. It was odd, she didn't often go anywhere without running. Rarely did these soulless trees pass by her so slowly.

She wasn't ready to be alone. She couldn't stand the idea of the only person to see her being gone, leaving her to the victims she clenched between her teeth. But her life would be very long, and the nights passed quickly. She trusted him when he said that he'll return soon.

Yes, of course. That's exactly what she told herself.

Hope was something a bit foreign to her since the ichor. But her horizons expanded the smallest amount once she found him. It was odd, the way he never cowered. The way that his first step was towards her.

It was like she was human again.

“I don't know how your fur stays so soft,” he giggled. His hands fidgeted with her silky coat, something he did often to keep his hands busy. She admired how easily he distracted himself.

Was he really as young as he appeared? He didn't look older than twelve.

She rustled her coat of hair around a bit, something that always made him chuckle. Something about the texture or the sound appeased his mind. When she wasn't around, he'd often lightly clap his hands together back and forth, or click his fingers together in consecutive order.

It happened much more often when he was happy or excited. Seeing the stimulation from him now eased her nerves the smallest amount. Not enough to clear the clouds in her mind, but enough for some light to pierce through, perhaps. 

They were going to the outskirts of Thoxica. Towards the “normal” portions of the world. She couldn't follow him all the way there, that portion of the world is far too dangerous for her.

Hunters, poachers, survivors. They didn't accept monsters like her. No, she belonged with the sludge and waste that made up her haunted home. 

His hand lifted off her shoulder long enough to point above the horizon, “There they are.” His eyes were wide and curious, almost as if trying to discover the inner workings of the space shuttle from so many miles away.

It was mind boggling to think that the colonizers made such a device. Was it safe for him to board that thing? How could she be sure that he was going to be safe?

“It's going to be okay, Wendi,” he reassured without even a gesture from her. His calm voice felt like a warm shower of affirmation, something nearly proprietary to the young boy with yellow eyes.

His footfalls stopped abruptly and he spun on his heels. With her reflexes it was slow, but she still remained precise; he wrapped his arms around her the tightest he could muster.

Ichor dripped from the bottom of her eyes.

She wrapped her long arms around his torso, making sure to be gentle with his frail figure. Muffled by her fur, he told her, “You better be ready for cool moon stories when I get back!”

She held him for as long as she could. It took minutes for him to finally slip away, his warm yellow eyes welling up with responsibility. “Stay safe, Wendi. I'll be home soon.”

He backed away for a moment before finally spinning around and making his way to the ship. And after a measly hour of wallowing, she finally dashed back into her dying forest.

Home, he called it. One day, he'll return home.

—

With the sun so high, Wendi was getting rather tired. Though the feeling immediately waged war with her growing hunger.

It's truly terrible how much it overcomes her. How impossible it is to think, the way she closes her eyes, just to open them to massacre. Hopefully if she got to it soon enough, she'd have a bit more agency.

Her hands and feet carried her well, speeding through the wasteland. Her senses were keen, but all she heard was sludge.

The sludges, creatures made purely of ichor. They're not food, and frankly, they're awful to conversate with. Given, they don't speak, nor think much at all.

Her head whipped left and right as she sped forward, back towards the camp. Nothing piqued her interest, and the hunger was approaching quickly. As it always does, impatient and hostile as ever.

She stepped on a pile of bones but only hesitated for a mere moment to confirm: it was picked clean. Most things around here were; if it wasn't something like her, it was the next creature down on the food chain. There's more hunger in these lands than there is food.

Maybe a sense of smell would help, as terrible as it would be.

It only took her minutes to traverse what she assumed to be a small handful of miles. She only feasted on flesh, but not even the shrubs had anything to offer anyways. Unfortunate, as the fewer leaves there are, the less creatures there are to eat them, and the less creatures for her to eat.

Something. There had to be something.

In fact, there was something tugging at her. Wendi's instincts dragged her forward, as if there was a leyline for her to follow.

She couldn't consider the scent, but she could feel. When there's creatures of living flesh, there's minds that make decisions. When there's a mind to make a decision, there's a radiating power that emanates off it. That was her lead, she could feel power somewhere this way.

Was she tracing her steps? She could have sworn she just came from this way.

The inquisitive eyes stared up at her, the eyes remaining from the body she ingested. Barely anything was left of it, really, but what was left was being studied.

They seemed peculiar, the person hunched over the body of the human she killed. They felt eerily familiar. Like she had just seen them.

She was grotesquely reminded, she's starving.

The smell of blood from that old carcass made her heart pound and her limbs vibrate. This is when it happens, this is where she no longer steers the ship.

The human hunched over the body stood up briskly against her presence, but she was already lunging.

Truthfully, it's sad. It's sad to watch helpless creatures get torn to shred. Often she only ever looks back at memories of what happened, it's all a blur when she's in the thick of it. The blood, the lingering tastes, the morality of it all, none of it really holds meaning in the moment. No, all of that comes after.

She opened her jaw, nearly wider than the human's entire upper body. She decided to close her eyes this time, it'll be over soon.

With a cacophonous clamp, she snapped her jaws shut. And finally–

Is that metal?

She opened her eyes with furious rage, only to see that the human was holding a blade between her teeth, leveraging her mouth ajar. Furiously, she bit through the blade, snapping it completely.

What a stern gaze they had.

Fire rocketed from their hands on either side of the weapon, heating her fur to bothersome temperatures. Without much time to respond, their fist met the bottom of her belly, a burning pain shooting up her torso.

Pain? Is this pain?

Skidding back a couple of meters, she planted her foot against the base of one of the sparse trees, firing herself forward. They lifted an arm in defense, a perfect lever for her to clamp down on.

This human was definitely unique in many ways. As her fangs tore into their skin, it became immediately apparent that they were more than flesh. Something rigid, much less crunchy than bones. Perhaps more metal beneath their skin.

With their left arm locked in her maws, they placed a flat palm on the boney shell of her face with the other hand. Her vision filled with a blinding glow as pain suppressed every joint in her body. A sudden jolt left her paralyzed for a moment, physically incapable of moving.

She witnessed crackles of violet energy branching from her fur, the whole world went stark white for a second before she realized that she was sprawled out along the ground.

The pain reminded her of some kind of humanity she once had. Never since the ichor had she struggled against her prey. Never had her joints hurt again, never had she witnessed herself impaired in any way.

Never had she met somebody who could survive her bite, much less match her speed.

The rage in her belly was baffled, struggling to pull forward again. With only her eyes, she watched the creature pull something from their back. It was an odd device they held, almost as if their arm reached in much further than the bag seemed in size.

With not a single change of expression, they tossed something out in front of her. It was a deep shade of red, hitting the ground with a light splatter.

It was meat.

A massive slab of something, perhaps the leg of a gigantic beast, dexterously separated from its skin.

She didn't take a second to ponder. Immediately she hurdled the limb into her mouth, impatiently devouring it whole. The satiation cooled her body from the inside out, leaving her just enough room to think.

But the one she wanted to ponder the most had disappeared in a cloud of yellow smoke.

Her head spun while she tried to process the occurrence. If she trusted her vision any less, she would have assumed that nothing happened at all. Luckily, she was very confident.

Slowly making her way back to her feet, she crept in close to where they stood before. Vanished without a trace.

The carcass was still there, barely perturbed in any way, physically. She didn't look too hard at it before, but from what she could recollect, it seemed ultimately the same. She checked behind her; there was a mild stain of blood from the meat she devoured. Just enough proof to feel somewhat sane.

A glance back at the body, and it clicked: those two were the same person.

Yes, yes she's sure of it. Those gray eyes, the cold stare, the utter poise in both combat and in death. Completely unfazed.

Perhaps this was the ichor playing tricks on her. Maybe something beyond her comprehension.

But they weren't a sludge, nor anything of the sort. No, she tasted the flesh and blood. They were human. Maybe not through and through, but to some substantial amount, they were human.

Her thoughts broke apart while she realized that she was as perplexed as she was exhausted. Emotional turmoil was rather rare for her nowadays, and as she learned today, she's less than fond of it.

Her hands dragged her body across the ashy soil until she found a divot in the ground. One sizable enough for her to make a temporary den out of, the decaying root system of a tree jutting out the side making some shelter. Burrowing down just a bit further, she made herself a sleeping quarters, fading in just moments.

Before she closed her eyes all the way, she thought to herself: I also have some stories for you, yellow eyed boy.
    `}</Text>
  </div>
)

export const metadata = {
  title: 'Worldless',
  author: 'Orident',
  date: 'Sept 7, 2024',
  accent: '#ffff00',
  foreground: 'black',
  id: 'worldless',
  snippet: "Wendi was happy that she had no sense of smell anymore. What a miserable existence it would be, feasting on carcasses and fresh kills with the odor of rotten decay filling her nostrils. Or at least, what's left of them, perhaps. After finishing her meal, her senses finally began to recollect for a moment. She looked down at the depravity she caused, somebody she's never met before. The guilt filled her stomach more than the meat did, but the sheer amount of times that it has happened helped scrub her with desensitization."
}

export default Story;