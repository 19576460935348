import { AnimatePresence, motion } from "framer-motion";
import { Animated, Utopia_Cover, Utopia_Talent_Tree, Utopia_White } from "../content/img";
import '../content/css/Home.css';
import { Center, Flex, Image, Stack } from "@mantine/core";

const UtopiaMain = () => (
  <motion.div
    initial={{ opacity: 0, y: 500 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 500 }}
    transition={{ type: "spring", stiffness: 100, damping: 20, duration: 0.1 }}
    className="utopia-main container"
  >
    <div className="row">
      <div className="slides">
        <img src={Utopia_White} className="logo" alt="Utopia Logo" />
        <h1>Utopia</h1>
        <p>Be anybody and go anywhere, ultimate customization.</p>
        <p>Scroll to learn more</p>
      </div>
    </div>
    <div className="row">
      <div className="slides">
        <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 0, xs: '30px' }} align={'center'} justify={'center'}>
          <Stack w={{ base: '50%', xs: '80%' }} align={{ base: 'end', xs: 'center' }} me={{ base: 30, xs: 0 }} ta={{ base: 'right', xs: 'center' }} justify="center">
            <h3>Utopia TTRPG</h3>
            <p>Welcome to a tabletop roleplaying game that will change the way you play, and you can play it now for free.</p>
            <p>Utopia TTRPG is a classless game system that fits within any world you can think of. Enjoy enough customization to breathe life into your characters without forcing them into small boxes for the rest of the game.</p>
          </Stack>
          <Image className="rainbow-border" src={Utopia_Cover} w={{ base: '10%', sm: '30%' }}/>
        </Flex>
      </div>
    </div>
    <div className="row">
      <div className="slides">
      <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 0, xs: '30px' }} align={'center'} justify={'center'}>
        <Image className="white-black-border" src={Utopia_Talent_Tree} w={{ base: '10%', sm: '30%' }}/>
          <Stack w={{ base: '50%', xs: '80%' }} align={{ base: 'end', xs: 'center' }} me={{ base: 30, xs: 0 }} ta={{ base: 'right', xs: 'center' }} justify="center">
            <h3>No Railroading</h3>
            <p>Using a talent tree system, you can maneuver from a tank to a healer to a tinkerer, all within a single campaign. Rather than being railroaded into a single class or being forced into your next ability, gain new features as you see fit.</p>
            <p>Witness as Utopia’s mechanics aid your storytelling experience, flaunting your character’s development as they discover themselves. With no limit to level and an infinitely scaling system, you can truly do anything.</p>
          </Stack>
        </Flex>
      </div>
    </div>
    <div className="row">
      <div className="slides">
        <Flex dir="row" justify={'center'}>
          <Stack w={{ base: '50%', xs: '80%' }} align="center" ms={30} style={{textAlign: 'justify'}} justify="justify">
            <h3>Custom Items, Custom Spells</h3>
            <p>Utopia TTRPG has a list of items and spells for all settings, whether that be ancient mythos or galactic exploration, but we know that you want more.</p>
            <p>Use the Advanced Ruleset to craft custom weapons, armor, and artifacts that truly speak to your character’s design. Gather and forage for new materials using a system that automatically balances all of the items expressed in your character’s portrait. Cast spells that fit your scenario, add your character’s name to the beginning of everything you create to truly bind them to this world.</p>
          </Stack>
        </Flex>
      </div>
    </div>
  </motion.div>
);


export default UtopiaMain;