import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.1.1</Title>
    <Title order={3} fs="bold">1st Year Prior</Title>
    <Text>{`Whatever you do, keep us hidden. The silver draconic tattoos scattered behind Cyreil’s hairline while they whispered into his ear. His trek back from Griffon’s Peak was fun, perhaps even life changing.

The war raged on across the canopy of vibrant trees, flames ravaged the lands that many called home, and frankly, he didn’t much mind any of it. As his skills as a mage progressed and he studied the ancient runes that the Twilight Forest had to offer him, meaning became scarce.

As he walked next to his closest friend, a young martialist named Nylo, he twiddled small shards of magical ice between his fingers. Most wouldn’t guess that Cyreil also grew up a martialist, but mana simply clicked in his mind. It’s like it spoke to him sometimes, spoken in a tongue that made more sense than the common speak.

When he finally migrated to the ranks of magic users, he was left wildly underwhelmed. Their teachings were boring and their knowledge was limited at best. “These runes come from the dwarves,” they said. “These markings are ancient,” they preached. Never did they speak of the whispers he hears, of the memories that mana had been holding suspended just above their heads.

Feeble minds, all of them.

Perhaps a portion of his interactions with the flux was akin to his cambion blood. Angelic cambions, creatures with white feathered wings and eyes that glow colorless during chants. His body was rather frail, but rarely did it matter much. Cyreil influenced his direct environment to great magnitudes, and his indirect environment more and more each day.

When the small crystal of ice was crushed beneath Cyreil’s knuckles, Nylo became aware that he was deep in thought again. Normally, this was something quite exciting for Nylo, but recently the sensation was tense. His eyes harbored something menacing.

Deep within the mountain, leading from the mouth called Griffon’s Peak, they entered the belly of some beast they never prepared for. At the basin there were artifacts, things that emanated power like nothing he’d seen before. Normally it would be exciting, but a hole slowly carved away at the base of his stomach.

It felt too easy. Too convenient for it to all land that way. Unguarded, unrestrained, easy to retrieve.

Cyreil was quick to pick up every single item he found. The blade, the veil, everything. Where the items ended up, Nylo wasn’t too sure. What he did know, however, was that there was a fresh spirit in the mage’s eyes, something nihilistic and sinister. He didn’t feel like the friend he grew up with anymore.

As if he heard it all in his mind, Cyreil whipped a glare at Nylo, making the man nearly flinch. Something he’s spent the better part of decade training to never do. No words were spoken, but every footfall sounded like buildings crashing down. He was sad to say it, but Nylo was nearly excited to make his way back to the battlefronts.

—

Cyreil spent his couple hours free in his quarters, speaking back and forth with the tattoos sputtering across his cheek bone.

The blade he held in his hands looked like a normal longsword to the untrained eye. Perhaps one made of brass, at most. Even more inspection would reveal that it was nearly indestructible. And those who truly understood the whispers, that it was designed to slay gods where they stand.

The silver tattoos scurried down his bicep and across his elbow, offering light kisses to the scars it left on the palm of his hand. Most would never see them, they weren’t designed for the eyes of the many. No, they were meant for eyes like his.

When Cyreil picked up the veil and the laces at the basin of the mountain, they fused into his skin. They promised him knowledge, a true understanding of this world. Answers to the questions he had been begging for. Truly, they did not disappoint.

The silky weave they were made of merged with his body, manifesting in the form of animated silver ink.

The mana speaks riddles heard by few, understood by less. You stand in the heart of it all, discovery is near.

It was a subject so powerful, yet so futile to explain. The way that all of the mana on the planet, the universe even, was closer to an organic being than it was a concept like energy or matter. A force that truly held the fabrics together, the flickers of chaos that held the balance at bay. Precisely, a balance that would mean the death of all. No honor, no martyrs; an empty husk of soulless nothing.

We need more. Your measly eyes and frail wings will not carry us.

Its voice was shrill, as if scratching the edges of his mind. Pain wasn’t much of a bother to him, but nuisance was not worth most costs. These tattoos were lucky he cared so much for his studies, simply put.

Here, here, and here.

Flashing auras gracefully flew from one side of his thoughts to the other. These were leylines, specifically those once lost to even the grand archmages. They heave and ho where the chaos decides to flow, accumulating in spaces called mana fluxes. These fluxes sometimes had simple causes, such as volcanoes and fault lines. Other times, they beseech powerful tools used to carve holes in the thin planar fabrics.

See clearer, move swiftly, accomplish all.

With the assistance of such a piercing voice, his path was clear. He sheathed the blade on his waist and adorned his arcane jewels. Taking a determined step to the door, it opened before he even grasped it, causing the light blue feathers on his wings to stand on edge.

Affronting him was a deathly gaze, a woman with vibrant purple eyes. She was determined, clashing her wits against his the moment eye contact was made. Without even a word, tension inflated the air around them; as if their objectives were apparent and contrasting. She stood stalwart against him, her expression unwavering.

He hated her already.

“You’re needed, Mr. Windkeeper,” she belted at him. It dawned on him, this was his new executive that he was told about before his fortnight of leave.

—

Cyriel nearly fell asleep during training, conjuring something impactful in his mind to pass the time. The sun was becoming sheepish, falling just behind the mountains he entered just a week ago.

His leaders often tried to handicap him in different ways; disallowing his wings, his vision, his hearing, or sometimes even all of the above. They argued with him constantly, saying that it would take more than cryomancy to win a war, though they seemed to struggle to say so much when they were frozen solid.

Luckily, a substantial moment was approaching soon. A naval brigade was spotted across the river, just about to cross below the Bridge of the Gods. The lunar scouts offered a rough timeline, saying that they would be here by the first moon light.

The Bridge of the Gods was massive, spanning over 100 meters where the river was its widest. How they planned to bring anything substantial across those waters unspotted he had no clue, but regardless the means they needed to be stopped.

The idiots that attempted to clip his wings must have not thawed out completely, given their half-baked ideas of throwing a net over the bridge to catch the ships. Cyreil was given a post in the center of the bridge, which he denied immediately. The lonely troops that chose to comply included Nylo, a friend he deemed too naive.

He meditated at the bank a kilometer up stream, listening in for disturbances in the leylines. If anything were to pass, he’d catch the sails in mere–

They approach, completely submerged.

He sprung to life, his eyes snapping open and his wings spanning out. In a vibrant blue glow, he jumped to the water, using his hands to feel where the disturbances came from. As the whispers said, they weren’t sailing ships, they were piloting submarines.

His eyes jolted towards the bridge, those oafs were about to get killed. Angelus was cunning if not ruthless, this was a trap. Cyreil would forfeit many lives, but unfortunately, Nylo was not one of them.

Cyreil’s feet levitated slowly off the ground while his wings blotched out the light behind him. His eyes glowed stark white as he connected himself to the leylines leading into the water, mustering every bit of strength he had.

You are too weak, Cyreil. Let us assist you.

The shrill voice tapped at his mind, hindering his focus. He cursed under his breath as crystals wandered along the bank. The frost started slow, but grew rapidly; a thin sheet of ice manifested itself as an unstoppable glacial wall.

His energy left him quickly, the pain made itself very apparent. As if he was sprinting against the wind, his body was struggling to lay the trap he devised.

Weak! You are weak! Let us show you!

His jaw clenched tight as he kept pushing onward. The ice was spreading further toward the other bank, perhaps a measly 20 meters down. He wouldn’t give in to pity so easily. His brows furrowed and his hands clenched as he drove himself towards the ground, latching onto everything he could. This water will freeze, or let it be his last breath.

WEAK!

“I am strong!” He shouted audibly, the mana bending to his whim around him. A stifled breath before he whispered, “And you will not trample over my wills.”

A gentle hand brushed against his shoulder, a warm aura cloaked itself around him. One of stern familiarity. In a single moment that lasted an entire hour, he basked in it, seating himself in its warm embrace.

Healed and invigorated, spires of ice shot their way across the chasm. A muffled crunch and a turbulent burst erupted from the river while he watched the machine explode and then sink to the bottom. His trembling arms eased for a moment before his knees buckled beneath him, leaving his body to gravity.

The gentle hands caught him before he fell, dexterous enough to even cradle his wings. The human that held them was cold but familiar, the love of his life. He deeply sighed before caressing their cheeks. Cyreil’s eyes fluttered shut in exhaustion, and he said through his muttere breathing, “Thank you, my love.”

Their deep gray eyes were cold and lifeless to an untrained eye, but they were warm to those who knew them well. “Of course,” Alyx said quietly.
`}</Text>
  </div>
)

export const metadata = {
  title: 'Dystopia',
  author: 'Orident',
  date: 'Sept 1, 2024',
  accent: '#9600ff',
  id: 'dystopia',
  snippet: "Whatever you do, keep us hidden. The silver draconic tattoos scattered behind Cyreil’s hairline while they whispered into his ear. His trek back from Griffon’s Peak was fun, perhaps even life changing. The war raged on across the canopy of vibrant trees, flames ravaged the lands that many called home, and frankly, he didn’t much mind any of it. As his skills as a mage progressed and he studied the ancient runes that the Twilight Forest had to offer him, meaning became scarce."
}

export default Story;