import { SimpleGrid, Card, Image, Group, Text, Badge, Button, Container } from '@mantine/core';
import { Link } from 'react-router-dom';
import { stories } from '../content/stories/index'

const MyramythMain = () => {
  return (
    <Container>
      <SimpleGrid cols={{ base: 1, sm: 2, lg: 3}}>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Card.Section>
            <Image
              src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
              height={160}
              alt="Norway"
            />
          </Card.Section>

          <Group justify="space-between" mt="md" mb="xs">
            <Text fw={500}>Short Stories</Text>
            <Badge>New: Oct 31, 2024</Badge>
          </Group>

          <Text size="sm" c="dimmed">
            <i>Everything had a pattern and everything had a reason. More often than not, everything just simply made sense. If one pushes on the door, naturally it will open...</i>
          </Text>
            <Link to="/myramyth/stories">
          <Button fullWidth mt="md" radius="md">
              Read my stories here
          </Button>
            </Link>
        </Card>

        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Card.Section>
            <Image
              src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
              height={160}
              alt="Norway"
            />
          </Card.Section>

          <Group justify="space-between" mt="md" mb="xs">
            <Text fw={500}>Merchandise</Text>
          </Group>

          <Text size="sm" c="dimmed">
            Find all of the Myramyth and Utopia TTRPG merchandise here! From hoodies to mousepads, and from mugs to keychains, its all here!
          </Text>

            <Link to="/myramyth/merch">
          <Button fullWidth mt="md" radius="md" disabled>
              Buy now! (Coming Soon!)
          </Button>
            </Link>
        </Card>

        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Card.Section>
            <Image
              src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
              height={160}
              alt="Norway"
            />
          </Card.Section>

          <Group justify="space-between" mt="md" mb="xs">
            <Text fw={500}>Privacy Policy</Text>
          </Group>

          <Text size="sm" c="dimmed">
            Privacy... sh.. <i>shmivacy</i>? Eh. This card takes you to the Privacy Policy. Definitely no fun and games there. Unless you're a lawyer maybe.   Or... maybe not? ;)
          </Text>

            <Link to="/myramyth/privacy-policy">
          <Button fullWidth mt="md" radius="md" disabled>
              Less-than-fun reading! (Coming Soon?)
          </Button>
            </Link>
        </Card>
      </SimpleGrid>
    </Container>
  );
}

export default MyramythMain;