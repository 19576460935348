import React, { useEffect } from "react";
import content from "../content/oridont";
import { Flex, Button, NavLink, Container } from "@mantine/core";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import "../content/css/Oridont.css";

function Oridont() {
  const location = useLocation();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  var navItems = [];

  const keys = Object.keys(content);
  for (let key of keys) {
    navItems.push(
      <Button
        href="#"
        key={key}
        label={key}
        onClick={() => {
          navigate(`?id=${key}`);
          toggle();
        }}
      >{key}</Button>
    );
  }

  console.log(navItems);

  var Component = () => {
    return (
      <Flex direction="column" ta={'center'}>
        <h2>Welcome!</h2>
        <h3>We've been expecting you...</h3>
      </Flex>
    );
  };

  if (searchParams.get("id") != null) {
    Component = content[searchParams.get("id")];
  }

  return (
    <Flex direction="column" gap="md" align={"center"} justify={"center"}>
      <Flex
        direction="row"
        maw={{ base: "50vw", sm: "80vw" }}
        ta={"center"}
        justify={"center"}
        align={"center"}
      >
        <h1>Oridont</h1>
      </Flex>
      <nav>
        <Flex direction="row" gap="md">
          {navItems}
          <Button onClick={() => navigate("/oridont/home")} c="black">
            Back
          </Button>
        </Flex>
      </nav>

      <Container className="story">
        <Component />
      </Container>
    </Flex>
  );
}

export default Oridont;
