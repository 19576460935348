// src/pages/About.js
import React, { useEffect } from "react";
import "../content/css/Utopia.css";
import { Myramyth_White, Utopia_White } from "../content/img";
import "../content/js/Galaxy.js";
import Galaxy from "../content/js/Galaxy.js";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import DiceHandler from '../components/DiceHandler.js';
import UtopiaMain from "../components/UtopiaMain";
import Downloads from "../components/Downloads.js";
import NewCompendium from "../components/NewCompendium.js";
import { Burger, Drawer, Stack, Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { nav } from "framer-motion/client";
import License from "../components/UtopiaLicense.js";

function Utopia() {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure();
  const [openPage, setOpenPage] = useState('');
  const smallSize = useMediaQuery('(max-width: 1200px)');

  const setPage = (page) => {
    close();
    setOpenPage(page);
  }

  useEffect(() => {
    setOpenPage('home');
  }, [])

  return (
    <div className="app">
      <Galaxy />
      <div className="description-box" id="description-box">
      </div>
      <nav className="utopia-nav">
        {/* Burger icon for smaller screens */}
        {smallSize && (
          <Burger opened={opened} onClick={open} size="lg" className="nav-burger" />
        )}

        {/* Navigation items for larger screens */}
        {!smallSize && (
          <ul>
            {/* Your navigation items */}
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => navigate('/')}
              >
                <img src={Myramyth_White} width={50}></img>
              </motion.button>
            </li>
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setPage('home')}
              >
                About
              </motion.button>
            </li>
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setPage('compendium')}
                disabled
              >
                Compendium (Soon)
              </motion.button>
            </li>
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setPage('dice_roller')}
              >
                Roller
              </motion.button>
            </li>
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setPage('foundry_module')}
                disabled
              >
                Foundry VTT (Soon)
              </motion.button>
            </li>
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setPage('license')}
              >
                Licensing
              </motion.button>
            </li>
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setPage('lore')}
                disabled
              >
                Lore (Soon)
              </motion.button>
            </li>
            <li>
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setPage('downloads')}
              >
                Downloads
              </motion.button>
            </li>
          </ul>
        )}

        {/* Drawer for smaller screens */}
        <Drawer
          opened={opened}
          onClose={close}
          size="xs"
          position="right"
          withCloseButton={false}
          padding="sm"
        >
          <Stack className="nav-drawer">
            <Button onClick={() => navigate("/")}>&lt; Back to Myramyth</Button>
            <Button onClick={() => setPage('home')}>About</Button>
            <Button onClick={() => setPage('compendium')} disabled>Compendium</Button>
            <Button onClick={() => setPage('dice_roller')}>Roller</Button>
            <Button onClick={() => setPage('foundry_module')} disabled>Foundry VTT Module</Button>
            <Button onClick={() => setPage('license')}>Licensing</Button>
            <Button onClick={() => setPage('lore')} disabled>Lore</Button>
            <Button onClick={() => setPage('downloads')}>Downloads</Button>
          </Stack>
        </Drawer>
      </nav>

      <div className="content">
        <AnimatePresence initial={true}>
          {openPage === "home" && <UtopiaMain key="utopia_main" />}
          {openPage === "compendium" && <NewCompendium key="compendium"/>}
          {openPage === "dice_roller" && <DiceHandler key="dice_roller"/>}
          {openPage === "license" && <License key="license"/>}
          {openPage === "downloads" && <Downloads key="downloads"/>}
        </AnimatePresence>

        {/* <AnimatePresence>
          {compendiumOpen ? null : <UtopiaMain/> }
        </AnimatePresence> */}
      </div>
    </div>
  );
}

export default Utopia;

{
  /* <motion.nav
initial={false}
animate={isOpen ? "open" : "closed"}
className='nav'
>
<div>
  <motion.button
    whileTap={{ scale: 0.97 }}
    onClick={() => setIsOpen(!isOpen)}
  >
    Compendium
  </motion.button>
  <motion.div
    variants={{
      open: {
        transition: {
          type: "spring",
          bounce: 0,
          duration: 0.7,
          delayChildren: 0,
          staggerChildren: 0.05
        },
        opacity: 1
      },
      closed: {
        transition: {
          type: "spring",
          bounce: 0,
          duration: 0.3
        },
        opacity: 0
      }
    }}
    style={{ pointerEvents: isOpen ? "auto" : "none" }}
  >
    <motion.ul
      
    >
      <motion.li variants={itemVariants}>Item 1 </motion.li>
      <motion.li variants={itemVariants}>Item 2 </motion.li>
      <motion.li variants={itemVariants}>Item 3 </motion.li>
      <motion.li variants={itemVariants}>Item 4 </motion.li>
      <motion.li variants={itemVariants}>Item 5 </motion.li>
    </motion.ul>
  </motion.div>
</div> 
<motion.button
  whileTap={{ scale: 0.97 }}
  onClick={() => navigate('/utopia/downloads')}
>
  Downloads
</motion.button>
</motion.nav>
<UtopiaNav></UtopiaNav> */
}
