import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.1.0</Title>
    <Title order={3} fs="bold">13E-842-244-164 (13th Eon, 842nd Megaannum, 244th Millenia, 164th Year)
    </Title>
    <Text>{`The world has always seemed so small to them. Or rather even, large, but simple at best.

Everything had a pattern and everything had a reason. More often than not, everything just simply made sense. If one pushes on the door, naturally it will open unless rendered ajar. If one pushes an object teetering on a cliff, it will fall down, given the force has enough strength to do so.

Everything in the universe worked on such fundamental ideas, just often more of them put together. Solar bodies are large and hot and difficult to look at, but that doesn’t make them any different. Gravity, physics, it all worked the exact same way.

If mana still existed, perhaps it would be something of the like as well.

People were often the exception to this rule. Sentient creatures always seemed to break the mold in what was logical or rational. As if they modified gravity in ways it wasn’t meant to be, or if they ignored the laws of physics when they so desired to.

Alyx spent most of their time alone for that very reason. Though they didn’t offer the same company that flesh and blood would, sometimes the androids around base sparked some great conversations.

“People feel a bit like the Hyperion Core,” Alyx said to the android that had been peering over their shoulder for the better part of the last hour. It cocked its head a bit, signaling its lack of understanding. Alyx stopped for a moment, staring at the blueprint they had sprawled neatly across the table, “The randomness of it all. If I were to pair up any number of atoms and put them under extreme pressures or heats, I could tell you what they would do. Perhaps sometimes, I wouldn’t know the result, but it wouldn’t change the fact that it would be the same every time. It would be measurable, repeatable.”

The android took a step back for a moment, its expressionless face pondering the question.

“But this device doesn’t make any sense. I mean, sure, bending space isn’t meant to be an easy question, but easy was never a goal in mind. Difficulty is no obstacle, but this just doesn’t work at all.”

The robot’s gears and motors seemed to click into place for a moment, “Perhaps you need to think like a human.”

Alyx was taken aback by this comment, stuck for a moment on what that meant. As simple as the idea was, it seemed more rhetorical than literal, which wasn’t something they often got from machines.

Think like a human? Interesting remark to make from somebody who is a human, and even more ironic to come from the processor of a computer chip. Though it didn’t take long for a bit of an epiphany to take place.

Their mind raced through the details, bit by bit, building the structure in their mind. There were voids where it didn’t make sense, where things didn’t line up, but that was okay. They didn’t have to make sense, yet.

In Alyx’s mind, they saw a metallic shell, somewhat of a shield that kept the extreme power of the core inside. It was something of a battery, one unlike anything else to ever exist, but its properties were so mind boggling. Anything this powerful would be murderous within a thousand miles, much less directly in front of it. But rather, it lay dormant.

Okay, so it needed to be activated. Something either within the shield or something that entered the shield would have to activate it. The immeasurable power needs to be siphoned in a controlled environment, immaculately precise measurements at a time.

And that’s where it all falls apart.

This odd core has no precision; it can’t be wagered with. Upon recent testing, interacting with it the same way a hundred times yielded a hundred different results. There was no rhyme or reason to how it actually functioned. It was simply a gate towards infinite possibilities, a near infinite power source, essentially laughing at them.

The android laid a cold, metal appendage on their shoulder, “Perhaps take a walk, Alyx.”

—

The planetary base they were stationed on was rather beautiful for what it was. Alyx didn’t spend too much time outside of Planetary Body 0, or what they used to call Pia. But recently, discoveries were popping out left and right across the entire solar system.

Since the Phexython died in front of Angelus, the behemoth’s carcass sprawled nearly an entire city. At first, this felt like a problem that would take years to solve; however, at the end of it all, it turned out to be a beacon for the greatest innovative revolution sentient life had ever seen.

Metals never seen before, materials that were nearly indestructible, and the most powerful energy source anybody had ever seen. The hypothetical device that used it as fuel, the Hyperion Core, would bend space around it, allowing the creatures of the universe to travel anywhere at any time.

“Perhaps randomness is a portion of the puzzle,” the android said. “Is it required in order to foot such a gargantuan bill?”

Alyx stared into its beady orange eyes. They were both sitting in a think tank with a false atmosphere, allowing them to walk and talk as if they were on new Pia, PB0. They stopped for a second to consider, though they immediately stood upright when the thought came to them. Alyx ran to their aeronaut dress chamber, haphazardly throwing their suit on. The android watched suspiciously, as a single malfunction in the suit would prevent Alyx from surviving the 20-step journey to the other building in the conditions of space, but they managed to secure everything properly, even in their haste.

Alyx spoke quietly to themselves while as they walked out, “Randomness, that’s it.”

The android with orange eyes followed them out, making their way through the airlock and past the breach. They listened through the intercom in Alyx’s helmet.

“If every object in existence, no matter how complex, has a predictable reaction,” Alyx continued to ramble, “then all outcomes would be predetermined. Regardless of what we know, measure, or successfully process, the outcome is always the same every time.”

They paused their speech for long enough to enter the airlock of the main planetary base and doff their suit in the equipment lounge, “No matter how we decide to modify or affect things, there’s only one real way for things to be. Because if we throw a hypothetical ball at a series of hypothetical dominos, they will fall directly according to the trajectory we gave it, and the same way every time.”

The android stared blankly, but intently, “And randomness changes this, yes?”

“Precisely,” they jogged to their desk, pulling out all of the blueprints and scratching notes into the papers. Alyx drew a dot and a line while they continued, “Naturally, if you were to compress the universe into a single dimension, essentially a line, it would go only one direction. But something that is truly random would act as a stop, a pivot point of sorts.”

They drew a dot at the end of the line, then hesitated. Alyx gripped the writing utensils with white knuckles, unsure of where to go from here, but the orange-eyed android finished the line. Its humanoid metal hand gently grabbed the pencil from them, placing it on the paper.

Alyx followed the line with their eyes; it started from a single point, then hit a point, splitting off into several lines. Their eyes widened, “The crux of time is true randomness.”

The automaton peered at the page for a moment, then nodded its head, “Yes. Truly, a mastermind’s dilemma.”

—

After leaving the main base, the android made its rounds. It did regular audits, checking the functionality of each bay, airlock, vital stations, and other necessities. This planet was still in the process of being terraformed, therefore these temporary stations would have to remain functional for years.

Luckily, without a rich atmosphere, corrosion was rarely any type of issue. The planet that they were currently stationed on was massive, one with extremely high gravity. Their chambers had to rest far above the actual surface of the celestial body in order to keep human-like life.

Huge cities were built upon rafts, essentially balloons, connected by massive bridges that each team member wagered across.

While it checked each station, each bridge support, and each city floating through the sky, somebody rested a light finger on its humanoid shoulder. It turned around, beaming its bright orange eyes at the specimen.

A human, one that bore a resemblance to the Alyx it was speaking to earlier but without any form of space protection, beamed a question directly into its mind, “Pardon me, but have I died recently anywhere around here, or am I still alive?”`}</Text>
  </div>
)

export const metadata = {
  title: 'Hyperion',
  author: 'Orident',
  date: 'Sept 7, 2024',
  accent: '#ff9600',
  foreground: 'black',
  id: 'hyperion',
  snippet: "The world has always seemed so small to them. Or rather even, large, but simple at best. Everything had a pattern and everything had a reason. More often than not, everything just simply made sense. If one pushes on the door, naturally it will open unless rendered ajar. If one pushes an object teetering on a cliff, it will fall down, given the force has enough strength to do so."
}

export default Story;