import { motion, AnimatePresence } from 'framer-motion';
import Dice from '../components/Dice';
import Galaxy from '../content/js/Galaxy';


const DiceRoller = ({
  diceArray,
  addDiceClick,
  removeDiceClick,
  updateDiceValue,
  clear,
  rollAll,
}) => {
  return (
    <div className="roller-container">
      <motion.div
        initial={{ opacity: 0, y: -1000 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -1000 }}
        className="dice-roller"
      >
        <motion.div id="dice-container" className="dice-container">
          <motion.button
            whileTap={{ scale: 0.97 }}
            onClick={removeDiceClick}
            className="add-dice"
          >
            -
          </motion.button>
          {diceArray.map(entry => (
            <Dice 
              key={entry.id} 
              diceId={entry.id}
              diceValue={entry.value}
              updateDiceValue={updateDiceValue}  
            />
          ))}
          <motion.button
            whileTap={{ scale: 0.97 }}
            onClick={addDiceClick}
            className="add-dice"
          >
            +
          </motion.button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default DiceRoller;
