export const License = () => {
  return (
    <div className="license-container">
      <div className="license">
        <ol className="column-1">
          <li>
            <strong>Introduction</strong>
            <p>
              This License Agreement ("Agreement") governs the use of the Utopia
              TTRPG System ("System"), which is currently in development and
              subject to change. By accessing or using the System, you agree to
              be bound by the terms of this Agreement.
            </p>
          </li>
          <li>
            <strong>Intellectual Property Rights</strong>
            <ul>
              <li>
                <strong>Ownership:</strong> All content within the System,
                including but not limited to rules, mechanics, and written
                material, is the property of Myramyth and Oridont and is
                protected by applicable copyright laws.
              </li>
              <li>
                <strong>Third-Party Materials:</strong> Not all illustrations
                and graphical assets are the property of Myramyth and Oridont.
                Such materials are used under license or with permission and
                remain the property of their respective owners.
              </li>
            </ul>
          </li>
          <li>
            <strong>License Grant</strong>
            <p>
              Myramyth and Oridont grants you a non-exclusive, non-transferable,
              limited license to use the System for personal, non-commercial
              purposes.
            </p>
          </li>
          <li>
            <strong>Restrictions</strong>
            <ul>
              <li>
                You may not reproduce, distribute, or create derivative works
                from the System without prior written consent from Myramyth and
                Oridont.
              </li>
              <li>
                You agree not to remove or alter any proprietary notices or
                labels on the System.
              </li>
            </ul>
          </li>
          <li>
            <strong>Disclaimer of Warranties</strong>
            <p>
              The System is provided "as is" and "as available," without
              warranties of any kind, either express or implied. Myramyth and
              Oridont does not guarantee that the System will be error-free or
              that defects will be corrected.
            </p>
          </li>
        </ol>
        <ol className="column-2">
          <li>
            <strong>Limitation of Liability</strong>
            <p>
              In no event shall Myramyth and Oridont be liable for any indirect,
              incidental, special, consequential, or punitive damages arising
              out of or related to your use of the System.
            </p>
          </li>
          <li>
            <strong>Updates and Changes</strong>
            <p>
              The System is in development and subject to change. Myramyth and
              Oridont reserves the right to modify or discontinue the System at
              any time without prior notice.
            </p>
          </li>
          <li>
            <strong>Termination</strong>
            <p>
              This Agreement is effective until terminated. Your rights under
              this Agreement will terminate automatically without notice if you
              fail to comply with any terms herein.
            </p>
          </li>
          <li>
            <strong>Governing Law</strong>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of [Your Jurisdiction], without regard to its
              conflict of law principles.
            </p>
          </li>
          <li>
            <strong>Entire Agreement</strong>
            <p>
              This Agreement constitutes the entire understanding between you
              and Myramyth and Oridont regarding the use of the System.
            </p>
          </li>
          <li>
            <strong>Contact Information</strong>
            <p>
              For any questions or concerns about this Agreement, please contact
              [Your Contact Information].
            </p>
          </li>
          <li>
            <p>
              <strong>
                Copyright © 2024 Myramyth and Oridont. All rights reserved.
              </strong>
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default License;
