const Minecraft = () => {
  return(
    <div class="flex-center">
      <h3>Official Oridont Community Minecraft Server</h3>
      <p>Ori has a Minecraft Server! You're probably here because you want to join it!</p>
      <p>Game version: 1.21.1</p>
      <p onClick={() => {
        navigator.clipboard.writeText("123.45.67.890");
        alert("Copied IP Address to Clipboard!");
      }}>IP Address: 123.45.67.890 
        <svg xmlns="http://www.w3.org/2000/svg" class="copy-icon" viewBox="0 0 448 512"><path fill="white" d="M208 0L332.1 0c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9L448 336c0 26.5-21.5 48-48 48l-192 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48zM48 128l80 0 0 64-64 0 0 256 192 0 0-32 64 0 0 48c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 176c0-26.5 21.5-48 48-48z"/></svg>
      </p>
    </div>
  );
}

export default Minecraft;