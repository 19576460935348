// src/pages/Home.js
import React from "react";
import {
  Animated,
  Utopia_White,
  Myramyth_White,
  Myramyth_Black,
  Logo_X,
  Logo_Patreon,
  Logo_Twitch,
  Logo_Youtube,
} from "../content/img/index";
import { Link } from "react-router-dom";
import { Image } from "@mantine/core";
import { motion } from "framer-motion";
import "../content/css/Home.css";

function Home() {
  return (
    <div className="container">
      <div className="row">
        <div className="slides slide-1">
          <Image maw={"20%"} className="main-logo" src={Myramyth_Black} />
          <div className="slides-header">
            <span>
              <video autoPlay loop muted playsInline>
                <source src={Animated} type="video/webm" />
                <p></p>
              </video>
            </span>
            <h1>&nbsp;Join the Narrative&nbsp;</h1>
            <span>
              <video autoPlay loop muted playsInline>
                <source src={Animated} type="video/webm" />
                <p></p>
              </video>
            </span>
          </div>
        </div>
      </div>
      {/* <div className="slides slide-2">
        <div className="slides-header">
          <h1>New here?</h1>
        </div>
        <div className="slides-button-container">
          <div className="slides-button-background"></div>
          <Link to="/oridont/home">
            <button className="slides-button">
              <span className="gt">&gt;&gt;&gt;</span> GO HERE <span className="lt">&lt;&lt;&lt;</span>
            </button>
          </Link>
        </div>
      </div> */}
      <div className="slides logo-gradient">
        <div className="row">
          <span className="col-6 image-gradient-container">
            <Link to="/utopia">
              <img width={"40%"} src={Utopia_White} />
              <div className="slide-header">
                <h1>Enter Utopia</h1>
              </div>
            </Link>
          </span>
          <span className="col-6 image-gradient-container">
            <Link to="/myramyth/home">
              <img width={"40%"} src={Myramyth_White} />
              <div className="slide-header">
                <h1>Enter Myramyth</h1>
              </div>
            </Link>
          </span>
        </div>
        <div className="row-3">
          <motion.footer className="socials">
            <a href="https://twitter.com/actuallyoridont" target="_blank">
              <img src={Logo_X} />
            </a>
            <a href="https://twitch.tv/oridont" target="_blank">
              <img src={Logo_Twitch} />
            </a>
            <a href="https://youtube.com/@oridont" target="_blank">
              <img src={Logo_Youtube}></img>
            </a>
            <a href="https://patreon.com/oridont" target="_blank">
              <img src={Logo_Patreon}></img>
            </a>
          </motion.footer>
        </div>
      </div>
    </div>
  );
}

const UtopiaTransition = ({ children }) => {
  return (
    <motion.div
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: "calc(50vw - 50%)", y: "calc(50vh - 50%)" }}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};

export default Home;
