// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, BrowserRouter } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Home from './pages/Home';
import Utopia from './pages/Utopia';
import Oridont from './pages/Oridont';
import Myramyth from './pages/Myramyth';
import '@mantine/core/styles.css';
import { MantineProvider, createTheme, MantineColorsTuple } from '@mantine/core';
import Error404 from './pages/errors/Error404';

const myColor: MantineColorsTuple = [
  '#fff8e1',
  '#ffefcb',
  '#ffdd9a',
  '#ffca64',
  '#ffba38',
  '#ffb01b',
  '#ffab09',
  '#e39500',
  '#cb8400',
  '#b07100'
];

const theme = createTheme({
  primaryColor: 'myColor',
  colors: {
    myColor,
  }
});

function App() {
  const location = useLocation();

  return (
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <AnimatePresence wait>
        <Routes>
          <Route
            path="/"
            element={
              <PageTransition>
                <Home />
              </PageTransition>
            }
          />
          <Route
            path="/utopia"
            element={
              <PageTransition>
                <Utopia />
              </PageTransition>
            }
          />
          <Route
            path="/oridont/*"
            element={
              <PageTransition>
                <Oridont />
              </PageTransition>
            }
          />
          <Route
            path="/myramyth/*"
            element={
              <PageTransition>
                <Myramyth />
              </PageTransition>
            }
          />
          <Route 
            path="*"
            element= {
              <Error404/>
            }
          />
        </Routes>
      </AnimatePresence>
    </MantineProvider>
  );
}


const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default App;
